import React from "react";
import "./productSection.scss";
import { MainTitle, ProductCard } from "../command";
import products from "../../product.json";
import CustomButton from "../command/customButton/customButton";
import { useNavigate } from "react-router-dom";

const ProductSection = () => {
  const navigate = useNavigate();

  const handleAllProClick = () => {
    navigate("/all-products");
  };

  return (
    <div className="home-product-section home-section-space">
      <div className="container-lg">
        <div className="row">
          <div className="col-12">
            <MainTitle mainTitle={"Our Products"} />
          </div>
          {products.slice(0, 6).map((productsData) => {
            const firstImage =
              productsData.productImage && productsData.productImage.length > 0
                ? productsData.productImage[0]
                : "./path/to/placeholder.jpg";
            return (
              <div className="col-xl-4 col-sm-6" key={productsData.id}>
                <ProductCard
                  productCardName={productsData.productName}
                  productCardCode={productsData.code}
                  imagePath={firstImage}
                  productDetailsLink={`/products/${productsData.id}`}
                />
              </div>
            );
          })}
          <div className="offset-md-4 col-md-4">
            <div className="d-flex justify-content-center">
              <CustomButton
                btnClass="home-button"
                btnText="Explore All products"
                onClick={handleAllProClick}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductSection;
