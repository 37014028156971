import React, { useState } from "react";
import "./footer.scss";
import { Form, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Mail, PhoneCall, Facebook, Instagram, Send } from "react-feather";
import InquireCatalog from "../../commandForm/inquireCatalog";
import MaterialAndCare from "../../productDetails/materialAndCare";
import PurchaseGuidelinesModal from "../../productDetails/purchaseGuidelinesModal";
import axios from "axios";

const Footer = () => {
  const [email, setEmail] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setSuccessMessage("");
    setErrorMessage("");

    try {
      // Replace with your API endpoint
      const response = await axios.post("http://aiiyn.in/footerForm.php", {
        email,
      });

      if (response.status === 200) {
        setSuccessMessage("Thank you for subscribing!");
        setEmail(""); // Clear the input field
      } else {
        setErrorMessage("Something went wrong. Please try again.");
      }
    } catch (error) {
      setErrorMessage("Failed to subscribe. Please check your connection.");
    } finally {
      setIsSubmitting(false);
    }
  };

  const [modals, setModals] = useState({
    inquireCatalog: false,
    materialCare: false,
    purchaseGuidelines: false,
  });

  const toggleModal = (modal) =>
    setModals((prev) => ({ ...prev, [modal]: !prev[modal] }));

  return (
    <>
      <footer className="wow animate__animated animate__fadeInUp">
        <div className="container-lg">
          <div className="row">
            {/* Brand Section */}
            <div className="col-lg-3 col-sm-6 footer-col">
              <div className="footer-brand-box">
                <Navbar.Brand href="#" className="logo">
                  aiiyn
                </Navbar.Brand>
                <h4 className="footer-brand-text">Furniture Design Studio</h4>
                <div className="social-media-links">
                  <a
                    className="footer-link"
                    href="https://www.facebook.com/aiiyn.india/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Facebook />
                  </a>
                  <a
                    className="footer-link"
                    href="https://www.instagram.com/aiiyn.in/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Instagram />
                  </a>
                  <a
                    className="footer-link"
                    href="https://api.whatsapp.com/send/?phone=%2B919998461209&text=Hey%2C%0AI+would+like+to+inquire+about+your+catalog%21%0A&type=phone_number&app_absent=0"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      className="img-fluid"
                      src="/assets/images/icon/whatappB.png"
                      alt="WhatsApp"
                    />
                  </a>
                </div>
              </div>
            </div>

            {/* Links Section 1 */}
            <div className="col-lg-3 col-sm-6 footer-col">
              {/* <h4 className="footer-title">Links</h4> */}
              <div className="footer-nav-links">
                <Link className="footer-link" to="/aboutus">
                  About Us
                </Link>
                <Link className="footer-link" to="/contactus">
                  Contact
                </Link>
                <Link className="footer-link" to="/career">
                  Career
                </Link>
                <Link className="footer-link" to="/contactus">
                  Book an Appointment for Studio Visit
                </Link>
              </div>
            </div>

            {/* Links Section 2 */}
            <div className="col-lg-3 col-sm-6 footer-col">
              {/* <h4 className="footer-title">More Information</h4> */}
              <div className="footer-nav-links">
                <a
                  className="footer-link"
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    toggleModal("inquireCatalog");
                  }}
                >
                  Catalog
                </a>
                <a
                  className="footer-link"
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    toggleModal("materialCare");
                  }}
                >
                  Material & Care
                </a>
                <a
                  className="footer-link"
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    toggleModal("purchaseGuidelines");
                  }}
                >
                  Purchase Guidelines
                </a>
                <Link className="footer-link" to="/all-products">
                  All Products
                </Link>
              </div>
            </div>

            {/* Subscribe Section */}
            <div className="col-lg-3 col-sm-6 footer-col">
              <div className="footer-subscribe">
                <h4 className="footer-title">Subscribe</h4>
                <Form onSubmit={handleSubmit}>
                  <Form.Group className="mb-3">
                    <div className="email-address">
                      <Form.Control
                        type="email"
                        placeholder="name@example.com"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                        disabled={isSubmitting}
                      />
                      <button type="submit" disabled={isSubmitting}>
                        {isSubmitting ? "Sending..." : <Send />}
                      </button>
                    </div>
                  </Form.Group>
                </Form>
                {successMessage && (
                  <p className="success-msg">{successMessage}</p>
                )}
                {errorMessage && <p className="error-msg">{errorMessage}</p>}
                <div className="footer-contact">
                  <div className="contact-info">
                    <PhoneCall />
                    <h5>+91 99984 61209</h5>
                  </div>
                  <div className="contact-info">
                    <Mail />
                    <h5>info@aiiyn.in</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>

      {/* Modals */}
      <InquireCatalog
        show={modals.inquireCatalog}
        onHide={() => toggleModal("inquireCatalog")}
      />
      <MaterialAndCare
        show={modals.materialCare}
        onHide={() => toggleModal("materialCare")}
      />
      <PurchaseGuidelinesModal
        show={modals.purchaseGuidelines}
        onHide={() => toggleModal("purchaseGuidelines")}
      />
    </>
  );
};

export default Footer;
