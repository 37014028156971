import React from "react";
import { Modal } from "react-bootstrap";

const DesignDetailsModal = ({ show, onHide, weightOfProduct }) => {
  return (
    <Modal
      show={show}
      onHide={onHide}
      size="xl"
      aria-labelledby="design-details-modal"
      centered
      className="modal-product-content"
    >
      <Modal.Header closeButton>
        <Modal.Title>Design Details and Customisation</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="product-details-modal">
          <div className="details-modal">
            {/* <lable>
              Our products are delivered fully assembled, ensuring they are
              ready for use without any assembly required on your end.
            </lable> */}
            <p>
              Our products are delivered fully assembled, ensuring they are
              ready for use without any assembly required on your end.
            </p>
            <p>We strongly recommend using our furniture indoors.</p>
            <p>
              Our designs are constantly evolving, and you might encounter
              slight variations in angles, elements, or textures compared to the
              online representations. Since each piece is meticulously
              handcrafted, there may be subtle variations in size, shape, and
              finish, making your furniture truly one-of-a-kind.
            </p>
            <p>
              <span>Weight :</span> {weightOfProduct}
            </p>
            <p>
              The weight listed for each piece is an estimate, including the
              entire product (foam, upholstery, plywood elements, hardware
              components, etc.). Teak's natural density variations and the
              handcrafting process can cause slight weight differences between
              pieces, even within the same design
            </p>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default DesignDetailsModal;
