import React, { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import axios from "axios";
import CustomButton from "../command/customButton/customButton";
import "./commandForm.scss";

const LetsCollaborateForm = ({ show, onHide }) => {
  const [formState, setFormState] = useState({
    name: "",
    companyName: "",
    personalEmail: "",
    professionalEmail: "",
    contactNumber: "",
    cityState: "",
    website: "",
    portfolioLink: "",
    message: "",
  });

  const [validated, setValidated] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const onInputChange = (e) => {
    const { name, value } = e.target;
    setFormState({ ...formState, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true);
      return;
    }

    setValidated(true);

    let formData = new FormData();
    Object.keys(formState).forEach((key) => {
      formData.append(key, formState[key]);
    });

    axios({
      method: "POST",
      url: "http://aiiyn.in/LetsCollaboratePFrom.php", // Replace with your API endpoint
      data: formData,
    })
      .then(() => {
        setShowSuccessMessage(true); // Show the success message
        setFormState({
          name: "",
          companyName: "",
          personalEmail: "",
          professionalEmail: "",
          contactNumber: "",
          cityState: "",
          website: "",
          portfolioLink: "",
          message: "",
        });
      })
      .catch(() => {
        alert("Submission failed. Try again!"); // Handle error
      });
  };

  const closeSuccessMessage = () => setShowSuccessMessage(false);

  return (
    <>
      {/* Main Form Modal */}
      <Modal
        show={show}
        onHide={onHide}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="custom-modal-form"
      >
        <Modal.Header closeButton>
          <Modal.Title>Let’s Collaborate</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <div className="row">
              {/* Name Field */}
              <Form.Group className="col-6 form-group" controlId="name">
                <Form.Label>
                  Name<span>*</span>
                </Form.Label>
                <Form.Control
                  required
                  type="text"
                  name="name"
                  value={formState.name}
                  onChange={onInputChange}
                  placeholder="Enter your name"
                />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              </Form.Group>

              {/* Company Name */}
              <Form.Group className="col-6 form-group" controlId="companyName">
                <Form.Label>
                  Company/Brand/Firm Name<span>*</span>
                </Form.Label>
                <Form.Control
                  required
                  type="text"
                  name="companyName"
                  value={formState.companyName}
                  onChange={onInputChange}
                  placeholder="Enter company name"
                />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              </Form.Group>

              {/* Personal Email */}
              <Form.Group
                className="col-6 form-group"
                controlId="personalEmail"
              >
                <Form.Label>
                  Personal Email<span>*</span>
                </Form.Label>
                <Form.Control
                  required
                  type="email"
                  name="personalEmail"
                  value={formState.personalEmail}
                  onChange={onInputChange}
                  placeholder="Enter personal email"
                />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              </Form.Group>

              {/* Professional Email */}
              <Form.Group
                className="col-6 form-group"
                controlId="professionalEmail"
              >
                <Form.Label>
                  Professional Email<span>*</span>
                </Form.Label>
                <Form.Control
                  required
                  type="email"
                  name="professionalEmail"
                  value={formState.professionalEmail}
                  onChange={onInputChange}
                  placeholder="Enter professional email"
                />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              </Form.Group>

              {/* Contact Number */}
              <Form.Group
                className="col-6 form-group"
                controlId="contactNumber"
              >
                <Form.Label>
                  Contact Number<span>*</span>
                </Form.Label>
                <Form.Control
                  required
                  type="text"
                  name="contactNumber"
                  value={formState.contactNumber}
                  onChange={onInputChange}
                  placeholder="Enter contact number"
                />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              </Form.Group>

              {/* City/State */}
              <Form.Group className="col-6 form-group" controlId="cityState">
                <Form.Label>
                  City / State<span>*</span>
                </Form.Label>
                <Form.Control
                  required
                  type="text"
                  name="cityState"
                  value={formState.cityState}
                  onChange={onInputChange}
                  placeholder="Enter city/state"
                />
                <Form.Control.Feedback type="invalid">
                  Please provide a valid city/state.
                </Form.Control.Feedback>
              </Form.Group>

              {/* Website */}
              <Form.Group className="col-6 form-group" controlId="website">
                <Form.Label>Website</Form.Label>
                <Form.Control
                  type="text"
                  name="website"
                  value={formState.website}
                  onChange={onInputChange}
                  placeholder="Enter website URL"
                />
              </Form.Group>

              {/* Portfolio Link */}
              <Form.Group
                className="col-6 form-group"
                controlId="portfolioLink"
              >
                <Form.Label>
                  Work Portfolio Link<span>*</span>
                </Form.Label>
                <Form.Control
                  required
                  type="text"
                  name="portfolioLink"
                  value={formState.portfolioLink}
                  onChange={onInputChange}
                  placeholder="Enter portfolio link"
                />
                <Form.Control.Feedback type="invalid">
                  Please provide a valid link.
                </Form.Control.Feedback>
              </Form.Group>

              {/* Message */}
              <Form.Group className="col-12 form-group" controlId="message">
                <Form.Label>Write a message or an idea</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  name="message"
                  value={formState.message}
                  onChange={onInputChange}
                  placeholder="Share your thoughts"
                />
              </Form.Group>
            </div>
            <Form.Group
              className="col-md-4 offset-md-4 form-group mb-0"
              controlId="submit"
            >
              <CustomButton type="submit" btnText="Submit form" />
            </Form.Group>
          </Form>
          {showSuccessMessage && (
            <div className="success-overlay" onClick={closeSuccessMessage}>
              <div className="success-message">
                <h3>Thank you!</h3>
                <p>
                  Thank you for reaching out to us with your vision for
                  collaboration. We’re thrilled by the possibility of creating
                  something beautiful together and we’ll be in touch with you
                  soon. In the meantime, if any questions arise, don’t hesitate
                  to reach out — we’re excited to begin this creative journey
                  with you.
                </p>
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default LetsCollaborateForm;
