import React from "react";
import { Breadcrumb } from "react-bootstrap";
import "./breadcrumb.scss";

const BreadcrumbSec = ({ BreadcrumbArray = [] }) => {
  return (
    <section className="breadcrumb-section">
      <div className="container-lg">
        <div className="row">
          <div className="col-12">
            <Breadcrumb>
              {BreadcrumbArray.map((item, index) => {
                const label = typeof item === "string" ? item : item.label;
                const href = typeof item === "string" ? "#" : item.href;
                const isLast = index === BreadcrumbArray.length - 1;

                return (
                  <Breadcrumb.Item key={index} href={href} active={isLast}>
                    {label}
                  </Breadcrumb.Item>
                );
              })}
            </Breadcrumb>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BreadcrumbSec;
