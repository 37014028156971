import React from "react";
import "./aiynCovalenceSection.scss";
import { LetsCollaborateForm, MainTitle } from "../command";
import CustomButton from "../command/customButton/customButton";

const AiynCovalenceSection = () => {
  const [aiynFormShow, setAiynFormShow] = React.useState(false);

  return (
    <>
      <div className="aiyn-covalenc-section home-section-space">
        <div className="container-lg">
          <div className="row">
            <div className="col-12">
              <MainTitle mainTitle="Aiyn Covalence" />
            </div>
            <div className="offset-md-2 col-md-8">
              <div className="aiyn-covalenc-content">
                <p>
                  At Aiyn, we believe that true creativity thrives through
                  collaboration.
                </p>
                <p>
                  With open hearts and open minds, we invite you to join forces
                  with us in shaping exceptional products and spaces that tell
                  stories and stir emotions.
                </p>
                <p>
                  Whether you're an architect, designer, artist, or creator of
                  any kind, let’s weave our talents together and create
                  something truly remarkable, something that transcends
                  expectations.
                </p>
                <div className="aiyn-covalenc-button">
                  <CustomButton
                    btnClass="home-button"
                    btnText="Let’s Collaborate"
                    onClick={() => setAiynFormShow(true)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*  */}
      <LetsCollaborateForm
        show={aiynFormShow}
        onHide={() => setAiynFormShow(false)}
      />
    </>
  );
};

export default AiynCovalenceSection;
