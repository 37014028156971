import React from "react";
import "./serviceCard.scss";

const ServiceCard = ({
  serviceCardImg,
  serviceCardTitle,
  serviceCardDetails,
  sCardClassName,
}) => {
  return (
    <div className={`service-card ${sCardClassName}`}>
      <div className="service-card-img">
        <img
          className="img-fluid"
          src={serviceCardImg}
          alt="service-card-img"
        />
      </div>
      <div className="service-card-content">
        <h3 className="service-card-title">{serviceCardTitle}</h3>
        <p className="service-card-details">{serviceCardDetails}</p>
      </div>
    </div>
  );
};

export default ServiceCard;
