import React, { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";
import CustomButton from "../command/customButton/customButton";
import "./careerModal.scss";
import axios from "axios";

const JuniorDesigner = ({ show, onHide }) => {
  const [validated, setValidated] = useState(false);
  const [formState, setFormState] = useState({
    fullName: "",
    emailId: "",
    contactNumber: "",
    cityState: "",
    portfolioLink: "",
    messageOrIdea: "",
  });

  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  const onInputChange = (e) => {
    const { name, value } = e.target;
    setFormState({
      ...formState,
      [name]: value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;

    // Prepare form data
    const formData = new FormData();
    Object.keys(formState).forEach((key) => {
      formData.append(key, formState[key]);
    });

    // Send form data via API
    axios({
      method: "POST",
      url: "http://aiiyn.in/JuniorDesignerPForm.php", // Replace with your email API endpoint
      data: formData,
    })
      .then((response) => {
        // Show success message
        setShowSuccessMessage(true);
        setFormState({
          fullName: "",
          emailId: "",
          contactNumber: "",
          cityState: "",
          portfolioLink: "",
          messageOrIdea: "",
        });
        setValidated(false);
      })
      .catch((error) => {
        console.error("There was an error submitting the form:", error);
        setShowErrorMessage(true);
      });

    setValidated(true);
  };

  const closeSuccessMessage = () => {
    setShowSuccessMessage(false);
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="custom-modal-form"
    >
      <Modal.Header closeButton>
        <Modal.Title>Junior Designer</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <div className="row">
            <Form.Group className="col-12 form-group" controlId="fullName">
              <Form.Label>Full Name*</Form.Label>
              <Form.Control
                required
                type="text"
                name="fullName"
                value={formState.fullName}
                onChange={onInputChange}
                placeholder="Full Name"
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="col-md-6 form-group" controlId="emailId">
              <Form.Label>Email ID*</Form.Label>
              <Form.Control
                required
                type="email"
                name="emailId"
                value={formState.emailId}
                onChange={onInputChange}
                placeholder="Email ID"
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>

            <Form.Group
              className="col-md-6 form-group"
              controlId="contactNumber"
            >
              <Form.Label>Contact Number*</Form.Label>
              <Form.Control
                required
                type="text"
                name="contactNumber"
                value={formState.contactNumber}
                onChange={onInputChange}
                placeholder="Contact Number"
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="col-md-6 form-group" controlId="cityState">
              <Form.Label>City, State*</Form.Label>
              <Form.Control
                required
                type="text"
                name="cityState"
                value={formState.cityState}
                onChange={onInputChange}
                placeholder="City, State"
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>

            <Form.Group
              className="col-md-6 form-group"
              controlId="portfolioLink"
            >
              <Form.Label>Work Portfolio Link*</Form.Label>
              <Form.Control
                required
                type="text"
                name="portfolioLink"
                value={formState.portfolioLink}
                onChange={onInputChange}
                placeholder="Link to your portfolio"
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="col-12 form-group" controlId="messageOrIdea">
              <Form.Label>Write a Message or an Idea</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                name="messageOrIdea"
                value={formState.messageOrIdea}
                onChange={onInputChange}
                placeholder="Share your message or idea"
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>
          </div>
          <Form.Group className="col-md-4 offset-md-4 form-group mb-0">
            <CustomButton type="submit" btnText="Submit" btnClass="w-100" />
          </Form.Group>
        </Form>

        {showSuccessMessage && (
          <div className="success-overlay">
            <div className="success-message">
              <h3>Thank you!</h3>
              <p>
                Thank you for submitting your application! We will carefully
                review your qualifications and get back to you within 48 hours.
              </p>
            </div>
          </div>
        )}

        {showErrorMessage && (
          <div className="error-message">
            <p>There was an error submitting your form. Please try again!</p>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default JuniorDesigner;
