import React from "react";
import "./subTitle.scss";

const SubTitle = ({ subTitle = "" }) => {
  return (
    <div className="sub-title">
      <h4 className="sub-title-text">{subTitle}</h4>
    </div>
  );
};

export default SubTitle;
