import React from "react";
import Button from "react-bootstrap/Button";
import "./NotFoundPage.scss";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../components/command/customButton/customButton";

const NotFoundPage = () => {
  const ViewAllProducts = useNavigate();
  const navigateViewAllProducts = () => {
    ViewAllProducts("/");
  };
  return (
    <section className="section-space notfound-main-section">
      <div className="notfound-sec">
        <h2 className="notfound-title">404</h2>
        <div className="notfound-text-box">
          <h3>We can't find that page</h3>
          <p>
            We're fairly sure that page used to be here, but seems to have gone
            missing. We do apologise on it's behalf.
          </p>
        </div>
        <CustomButton btnText="Go To Home" onClick={navigateViewAllProducts} />
      </div>
    </section>
  );
};

export default NotFoundPage;
