import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules"; // Corrected import
import { ProductCard } from "../command";
import "swiper/css";
import "swiper/css/navigation";
import products from "../../product.json";
import "./productSlider.scss";

const ProductSlider = () => {
  return (
    <section className="product-slider-section">
      <div className="customisation-chart">
        <h3>View More Products</h3>
        <Swiper
          modules={[Navigation]} // Use the corrected Navigation module
          spaceBetween={30}
          slidesPerView={3}
          navigation
          breakpoints={{
            320: {
              slidesPerView: 1,
            },
            768: {
              slidesPerView: 2,
            },
            1024: {
              slidesPerView: 3,
            },
          }}
        >
          {products.map((productsData) => {
            const firstImage =
              productsData.productImage && productsData.productImage.length > 0
                ? productsData.productImage[0]
                : "./path/to/placeholder.jpg";
            return (
              <SwiperSlide key={productsData.id}>
                <ProductCard
                  productCardName={productsData.productName}
                  productCardCode={productsData.code}
                  imagePath={firstImage}
                  productDetailsLink={`/products/${productsData.id}`}
                />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </section>
  );
};

export default ProductSlider;
