import logo from "./logo.svg";
import "./App.scss";
import { Header } from "./components";
import { Outlet } from "react-router-dom";
import { Footer } from "./components/command";
import ScrollToTop from "./ScrollToTop";

function App() {
  return (
    <div className="">
      <ScrollToTop />
      <Header />
      <div className="main-section">
        <Outlet />
      </div>
      <Footer />
    </div>
  );
}

export default App;
