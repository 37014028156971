import React, { useState } from "react";
import "./contactUsForm.scss";
import { Form } from "react-bootstrap";
import axios from "axios";
import CustomButton from "../customButton/customButton";
import MainTitle from "../mainTitle/mainTitle";

const ContactUsForm = () => {
  const [validated, setValidated] = useState(false);
  const [formState, setFormState] = useState({
    name: "",
    mobileNumber: "",
    email: "",
    cityState: "",
    message: "",
  });
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const onInputChange = (e) => {
    const { name, value } = e.target;
    setFormState({ ...formState, [name]: value });
  };

  const handleSubmit = (event) => {
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
      return;
    }

    setValidated(true);
    event.preventDefault();

    axios({
      method: "POST",
      url: "http://aiiyn.in/contactUsFormPForm.php",
      data: formState,
    })
      .then(() => {
        setShowSuccessMessage(true);
        setFormState({
          name: "",
          mobileNumber: "",
          email: "",
          cityState: "",
          message: "",
        });
        setValidated(false);
      })
      .catch(() => {
        alert("Failed to submit the form. Please try again.");
      });
  };

  const closeSuccessMessage = () => {
    setShowSuccessMessage(false);
  };

  return (
    <>
      <section className="contact-us-form section-space">
        <div className="container-lg">
          <div className="row">
            <div className="col-md-10 offset-md-1">
              <MainTitle mainTitle="Get in touch" />
              <div className="contact-us-content">
                <p>
                  <span>We’d love to hear from you!</span>
                  Whether you have questions, need more information, or are
                  interested in a custom piece, don’t hesitate to reach out. We
                  are here to help and provide personalized assistance.
                  <span>
                    Get in touch with us today, and let’s bring your vision to
                    life.
                  </span>
                </p>
              </div>
            </div>
            <div className="col-md-8 offset-md-2">
              <div className="contact-us-form-sec">
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                  <div className="row">
                    {/* Name */}
                    <Form.Group
                      className="col-md-6 form-group"
                      controlId="name"
                    >
                      <Form.Label>Name*</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="name"
                        value={formState.name}
                        onChange={onInputChange}
                        placeholder="Name"
                      />
                      <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    </Form.Group>

                    {/* Mobile Number */}
                    <Form.Group
                      className="col-md-6 form-group"
                      controlId="mobileNumber"
                    >
                      <Form.Label>Mobile Number*</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="mobileNumber"
                        value={formState.mobileNumber}
                        onChange={onInputChange}
                        placeholder="Mobile Number"
                      />
                      <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    </Form.Group>

                    {/* Email */}
                    <Form.Group
                      className="col-md-6 form-group"
                      controlId="email"
                    >
                      <Form.Label>Email Id*</Form.Label>
                      <Form.Control
                        required
                        type="email"
                        name="email"
                        value={formState.email}
                        onChange={onInputChange}
                        placeholder="Email Id"
                      />
                      <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    </Form.Group>

                    {/* City / State */}
                    <Form.Group
                      className="col-md-6 form-group"
                      controlId="cityState"
                    >
                      <Form.Label>City / State*</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="cityState"
                        value={formState.cityState}
                        onChange={onInputChange}
                        placeholder="City / State"
                      />
                      <Form.Control.Feedback type="invalid">
                        Please provide a valid city/state.
                      </Form.Control.Feedback>
                    </Form.Group>

                    {/* Message */}
                    <Form.Group
                      className="col-12 form-group"
                      controlId="message"
                    >
                      <Form.Label>Write a message or an idea</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        name="message"
                        value={formState.message}
                        onChange={onInputChange}
                        placeholder="Share your thoughts"
                      />
                    </Form.Group>
                  </div>

                  {/* Submit Button */}
                  <Form.Group className="col-md-4 offset-md-4 form-group mb-0">
                    <CustomButton
                      type="submit"
                      btnText="Submit form"
                      btnClass="fill-width"
                    />
                  </Form.Group>
                </Form>
              </div>
            </div>
          </div>
        </div>

        {/* Success Message Overlay */}
        {showSuccessMessage && (
          <div className="success-overlay contact-from-succ">
            <div className="success-message">
              <button className="close-btn" onClick={closeSuccessMessage}>
                &times;
              </button>
              <h3>Thank You!</h3>
              <p>
                Thank you for reaching out! We’ve received your details, and our
                team will be in touch within the next 24 hours. In the meantime,
                please don’t hesitate to contact us directly if you have any
                further questions.
              </p>
            </div>
          </div>
        )}
      </section>
    </>
  );
};

export default ContactUsForm;
