import React from "react";
import { Modal } from "react-bootstrap";
import "./productDetails.scss";

const MaterialAndCare = ({ show, onHide }) => {
  return (
    <Modal
      show={show}
      onHide={onHide}
      size="xl"
      aria-labelledby="design-details-modal"
      centered
      className="modal-product-content"
    >
      <Modal.Header closeButton>
        <Modal.Title>Material and Care</Modal.Title>
      </Modal.Header>
      <Modal.Body className="">
        <div className="product-details-modal">
          <div className="details-modal">
            <lable>Teak:</lable>
            <p>(Botanical name: Tectona Grandis, Local name: Valsadi Saag)</p>
          </div>
          <div className="details-modal">
            <lable>Get to know the material:</lable>
            <p>
              We take immense pride in our teak furniture, crafted from
              carefully sourced reclaimed and seasoned teak. This exceptional
              material, known for its strength and durability, develops a rich
              hue over time, accentuated by its natural grain pattern. With its
              high oil content and tight grain structure, teak is
              weather-resistant and perfect for crafting high-quality furniture.
              The natural aging process, purely cosmetic, doesn't compromise the
              wood's structural integrity. Overall, teak is cherished globally
              for its enduring beauty, durability and cultural significance.
            </p>
            <p>
              We understand that furniture choices are personal, which is why at
              Aiyn, we offer the choice of both reclaimed and plantation teak
              for crafting your furniture. While both options deliver
              exceptional strength and durability, reclaimed teak offers a
              unique character and story, while fresh teak provides a lighter
              base. Please note that visual appearance, product weight, and
              grain patterns may vary slightly based on the type of teak used
            </p>
          </div>
          <div className="details-modal">
            <lable>Care, Precautions, and Guidelines for Teak:</lable>
            <p>
              <span>Regular cleaning :</span> We recommend regularly dusting the
              furniture with a soft, clean, and dry cloth to maintain the
              quality and appearance of the teak. Occasionally, it may be wiped
              with a soft cloth lightly dampened with water. Please avoid using
              water or harsh chemicals when cleaning.
            </p>
            <p>
              <span>Daily Care :</span> We acknowledge the considerable weight
              associated with teak furniture, a testament to its strength and
              durability. We kindly ask you to refrain from dragging the
              furniture across the floor to prevent potential structural
              weakening and damage to flooring materials. Additionally, please
              avoid placing extremely hot or cold items directly onto the teak
              or veneer surfaces of your furniture to maintain the quality.
            </p>
            <p>
              <span>Seasonal and Weather Impact :</span> Teak, like all natural
              materials, adapts to its environment. Seasonal and weather
              fluctuations can cause subtle changes in the shape and dimension
              of the teak. This is a characteristic inherent to natural
              materials and shouldn't be a cause for concern.
            </p>
            <p>
              <span>Sunlight Exposure :</span> We strongly recommend using our
              furniture indoors, as prolonged exposure to direct sunlight can
              cause slight fading of the polish and potentially alter the colour
              of the teak. Additionally, avoid placing the furniture in rooms or
              spaces that experience extreme temperature fluctuations, as these
              can cause stress on joineries and other elements, potentially
              leading to splitting and cracks.
            </p>
            <p>
              <span>Teak Polish :</span> The final shade of the polish may vary
              slightly between pieces due to our hand-polishing process, which
              allows the natural hues of the wood to shine through, resulting in
              a beautiful and organic variation. Furthermore, please avoid
              contact with chemicals such as harsh cleaning liquids, candle wax,
              essential oils, and air fresheners, as the polish is sensitive to
              liquids and may damage the teak.
            </p>
            <p>
              <span>Re polishing :</span> Teak's inherent oils provide a
              beautiful lustre. To maintain this shine, a gentle re-polishing
              every 4-6 years is recommended, but not mandatory. This subtle
              touch can help restore the wood's natural glow, if desired.
            </p>
            <p>
              <span>Damage care :</span> While our teak furniture is crafted for
              lasting beauty, everyday wear and tear can occur. If your
              furniture faces damage, staining, scratching, breakage, or
              requires re-upholstery or re-polishing, we're here to assist you.
              Contact us directly for guidance and potential solutions for any
              issues or seek professional help nearby. By addressing any
              concerns promptly, you can ensure your cherished teak furniture
              graces your home for years to come.
            </p>
            <p>
              <span>Lighting affect :</span> Please be aware that both natural
              and artificial lighting can influence the perceived colour of the
              teak and its polish. For an accurate match, we suggest visiting
              our studio or requesting samples to complement your space's style
            </p>
          </div>
          <div className="details-modal">
            <lable>Upholstery fabrics:</lable>
            <p>
              We're passionate about creating furniture that feels grounded and
              has a sense of rootedness, which is why we often choose linen or
              cotton-based upholstery. Natural fibres are known for its
              breathability, durability, and timeless aesthetic, draping
              beautifully and softening over time, adding a touch of relaxed
              elegance to your space.
            </p>
            <p>
              Since these fabrics contain natural threads, there may be slight
              variations in colour and texture between batches. Additionally,
              it's normal for fabrics of this kind to develop a subtle pile over
              time, which can be cleaned with regular care. This a testament to
              the inherent beauty of fibres with a blend of Linen, Cotton,
              Polyester. Kindly note that lighting conditions can affect how the
              fabric appears.
            </p>
            <p>
              For this specific collection, the printed fabric showcased is
              custom-designed on our preferred blend of natural fibres,
              including linen and cotton. Please be aware that variations in
              visual appearance may occur due to the custom-made nature of our
              fabric. We aim to ensure the highest quality standards in every
              aspect of the process and appreciate your understanding of these
              inherent characteristics of our custom-made fabric.
            </p>
          </div>
          <div className="details-modal">
            <lable>Keeping Your Upholstery Clean:</lable>
            <p>
              <span>General Care :</span> As we recommend our furniture for
              indoor use, extended exposure to harsh sunlight can cause the
              fabric colour to fade. Additionally, avoid applying harsh cleaning
              chemicals to ensure longevity.
            </p>
            <p>
              <span>Regular Vacuuming :</span> Routinely clean your upholstery
              with a simple dusting cloth to remove dust. Alternatively, you can
              opt for vacuum cleaning for efficient cleaning.
            </p>
            <p>
              <span>Professional Touch :</span> For a deep clean, occasional
              professional cleaning is recommended.
            </p>
            <p>
              <span>Spot Cleaning :</span> Quickly attend to minor spills by
              gently blotting them with a clean, damp cloth or seek assistance
              from a professional cleaner. Prior to using any cleaning solution,
              we advise you to do a patch test.
            </p>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default MaterialAndCare;
