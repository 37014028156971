import React from "react";
import "./serviceSection.scss";
import { MainTitle, ServiceCard } from "../command";

const ServiceSection = () => {
  return (
    <section className="service-section home-section-space">
      <div className="container-lg">
        <div className="row">
          <div className="col-12">
            <MainTitle mainTitle={"Services We Provide"} />
          </div>
          <div className="col-md-12">
            <ServiceCard
              serviceCardImg="/assets/images/service/CUSTOMISATION.jpg"
              serviceCardTitle="Customization"
              serviceCardDetails="At Aiyn, we offer extensive customization options for our current collections and products. From altering dimensions to selecting finishes and materials, you can personalize each piece to your exact preferences. This ensures that your furniture is a perfect match for your unique vision and space."
            />
          </div>
          <div className="col-md-12">
            <ServiceCard
              sCardClassName="service-right-data"
              serviceCardImg="/assets/images/service/BESPOKE-DESIGN.jpg"
              serviceCardTitle="Bespoke Designs"
              serviceCardDetails="Your Vision, Our Design. At Aiyn, we offer bespoke design solutions tailored to your unique needs, creating a one-of-a-kind experience. Whether it's a single product or a complete setup for any space, our designs are carefully crafted with attention to detail, blending personalization with our signature style to deliver timeless, cohesive pieces."
            />
          </div>
          <div className="col-md-12">
            <ServiceCard
              serviceCardImg="/assets/images/service/ARCHITECTURE-CONSULTANCY.jpg"
              serviceCardTitle="Architectural & Interior Consultancy"
              serviceCardDetails="We are a team of architects and designers who tailor our designs to each unique project. Furniture is more than just a product—it’s a vital part of the broader design concept. Our approach integrates furniture with architecture and interiors, crafting spaces that are both functional and inspiring, elevating everyday moments."
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServiceSection;
