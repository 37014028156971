import React from "react";
import { Button } from "react-bootstrap";
import "./customButton.scss";

const CustomButton = ({
  btnType = "",
  btnText = "",
  btnClass = "",
  onClick,
}) => {
  return (
    <Button
      type={btnType}
      onClick={onClick}
      className={`${btnClass} custom-btn`}
    >
      {btnText}
    </Button>
  );
};

export default CustomButton;
