import React from "react";
import "./productDetails.scss";
import { Modal } from "react-bootstrap";

const PurchaseGuidelinesModal = ({ show, onHide }) => {
  return (
    <Modal
      show={show}
      onHide={onHide}
      size="xl"
      aria-labelledby="design-details-modal"
      centered
      className="modal-product-content"
    >
      <Modal.Header closeButton>
        <Modal.Title>Purchase Guidelines</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="product-details-modal">
          <div className="details-modal">
            <lable>Before Placing an Order :</lable>
            <p>
              Prior to placing your order, we recommend carefully measuring your
              entryway, doorways, hallways, and stairwells. Verifying that these
              dimensions are wider than your chosen furniture piece allows for a
              smooth and unimpeded delivery, saving us potential inconvenience.
            </p>
          </div>
          <div className="details-modal">
            <lable>Payment :</lable>
            <p>
              All our furniture is custom-made to order, therefore full payment
              is required upfront for smaller orders, while bulk orders require
              a 70% deposit – reflecting the investment of materials and
              craftsmanship to create bespoke pieces.
            </p>
          </div>
          <div className="details-modal">
            <lable>Delivery :</lable>
            <p>
              <span>Lead time :</span> Please allow 5-7 weeks for the completion
              of standard orders. Delivery timelines for bulk orders may vary.
            </p>
            <p>
              <span>Delivery charges :</span> Delivery charges are not included
              in the base price and will be calculated based on your location.
            </p>
          </div>
          <div className="details-modal">
            <lable>Damaged pieces and Mishandling :</lable>
            <p>
              We take pride in the quality of our products and ensure their safe
              arrival. In the unlikely event that a piece arrives damaged, we
              take full responsibility and will either repair or replace it at
              no additional cost to you.
            </p>
            <p>
              Please note that we are not liable for damage caused by product
              mishandling after the delivery. Any repairs required due to
              post-delivery mishandling will incur a service charge.
            </p>
          </div>
          <div className="details-modal">
            <lable>Payment Methods :</lable>
            <p>
              For your convenience, we offer secure online payment methods
              including credit cards, debit cards, popular wallet platforms, and
              net banking. To ensure a smooth order fulfilment process, all
              goods are dispatched only after receiving full payment.
            </p>
          </div>
          <div className="details-modal">
            <lable>Cancellations and return :</lable>
            <p>
              Once an order is placed, cancellations are not permitted. We
              kindly ask you to review your orders carefully before confirming
              to avoid any discrepancies or misunderstandings. Additionally,
              products cannot be returned unless they arrive damaged.
            </p>
          </div>
          <div className="details-modal">
            <lable>Customer Service :</lable>
            <p>
              Our team is happy to answer any questions you may have, both
              before and after placing your order or receiving the delivery.
              Feel free to contact us for any doubts or special customization
              requests beyond what's available in our catalogue.
            </p>
          </div>
          <div className="details-modal">
            <lable>Intellectual Property :</lable>
            <p>
              All materials on this website, including text, graphics, logos,
              images, audio, video, data, and software (collectively referred to
              as "Content"), are either owned by Aiyn or licensed. This Content
              is protected by copyright, trademark, patent, and other
              intellectual property rights. Unauthorized use or reproduction may
              lead to legal consequences.
            </p>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default PurchaseGuidelinesModal;
