import React from "react";
import "./AboutCard.scss";
import MainTitle from "../mainTitle/mainTitle";
import CustomButton from "../customButton/customButton";

const AboutCard = ({ aboutMainTitle, aboutSubText, aboutText }) => {
  return (
    <div className="about-section-content">
      <div className="about-content-box">
        <p className="about-text">
          <span>{aboutSubText}</span>
          {aboutText}
        </p>
      </div>
    </div>
  );
};

export default AboutCard;
