import React from "react";
import "./mainTitle.scss";

const MainTitle = ({ mainTitle = "" }) => {
  return (
    <div className="main-title">
      <h2 className="main-title-text">{mainTitle}</h2>
    </div>
  );
};

export default MainTitle;
