import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import products from "../../product.json"; // Import products data
import { BreadcrumbSec } from "../../components/command";
import { ProductDetails } from "../../components";

const ProductDetailsPage = () => {
  const sProducts = products;
  const { productId } = useParams();
  const [details, setDetails] = useState([]);
  useEffect(() => {
    sProducts.map((item) => {
      if (item.id === productId) {
        return setDetails(item);
      }
    });
  }, [productId]);
  const breadcrumbItems = [
    { label: "Home", href: "/" },
    { label: "All Products", href: "/all-products" },
    // Add breadcrumb for the current product if needed
    { label: details?.productName, href: `/products/${details?.id}` },
  ];

  console.log(details.productImage);
  return (
    <div className="product-details-page">
      <BreadcrumbSec BreadcrumbArray={breadcrumbItems} />
      {details && (
        <ProductDetails
          productImages={details.productImage} // Assuming productImages is a property in your product object
          productDName={details.productName} // Assuming productName is a property in your product object
          productDCode={details.code} // Assuming code is a property in your product object
          productDDetails={details.productDetails} // Assuming productDetails is a property in your product object
          customisationChart={details.customisationChart}
          weightOfProduct={details.weightOfProduct}
        />
      )}
    </div>
  );
};

export default ProductDetailsPage;
