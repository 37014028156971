import React, { useState, useEffect } from "react";
import "./header.scss";
import { Container, Navbar, Offcanvas, Nav } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";

function MyNavbar({ isMobile, closeMenu }) {
  const location = useLocation();

  const links = [
    { to: "/", label: "Home" },
    { to: "/all-products", label: "All Products" },
    // { to: "/all-products", label: "Arka" },
    // { to: "/all-products", label: "Blog" },
    { to: "/aboutus", label: "About Us" },
    { to: "/career", label: "Careers" },
    { to: "/contactus", label: "Contact Us" },
  ];

  return (
    <Nav className={`nav-links ${isMobile ? "mobile-nav" : "desktop-nav"}`}>
      {links.map((link) => (
        <Nav.Link
          as={Link}
          to={link.to}
          key={link.to}
          className={
            location.pathname === link.to ? "nav-link active" : "nav-link"
          }
          onClick={isMobile ? closeMenu : undefined} // Close menu on mobile
        >
          {link.label}
        </Nav.Link>
      ))}
    </Nav>
  );
}

const Header = () => {
  const [show, setShow] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const location = useLocation();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    handleClose(); // Close menu when route changes
  }, [location]);

  useEffect(() => {
    const handleScroll = () => setScrolled(window.scrollY > 10);
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <header className={scrolled ? "scrolled" : ""}>
      <Navbar bg="light" expand="lg" sticky="top">
        <div className="container-lg">
          <Link to="/" className="logo">
            aiiyn
          </Link>
          <Navbar.Toggle
            aria-controls="offcanvasNavbar"
            className="d-lg-none"
            onClick={handleShow}
          />
          <Navbar.Collapse className="d-none d-lg-flex">
            {/* Inline Navbar for Desktop */}
            <MyNavbar isMobile={false} />
          </Navbar.Collapse>
          <Navbar.Offcanvas
            id="offcanvasNavbar"
            aria-labelledby="offcanvasNavbarLabel"
            placement="end"
            show={show}
            onHide={handleClose}
            className="d-lg-none"
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title id="offcanvasNavbarLabel">Menu</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <MyNavbar isMobile={true} closeMenu={handleClose} />
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </div>
      </Navbar>
    </header>
  );
};

export default Header;
