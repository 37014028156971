import React from "react";
import "./homeBanner.scss";

const HomeBanner = () => {
  return (
    <div
      className="home-main-banner"
      style={{ backgroundImage: 'url("/assets/images/home-banner.jpg")' }}
    >
      <div className="home-main-content">
        <div className="container-lg">
          <div className="row">
            <div className="col-lg-5">
              <div className="home-main-text">
                <h3>welcome to 'aiiyn'</h3>
                <p>
                  At Aiiyn, we aspire to connect with you through our range of
                  handcrafted products, carefully designed to create a luxurious
                  lifestyle.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeBanner;
