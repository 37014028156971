import React from "react";
import {
  BreadcrumbSec,
  PageTitleImage,
  ProductCard,
} from "../../components/command";
import products from "../../product.json";

const AllProductsPage = () => {
  const breadcrumbItems = [
    { label: "Home", href: "/" },
    { label: "All Products", href: "/" },
  ];

  return (
    <div className="all-products-page">
      <div className="wow animate__animated animate__fadeInUp">
        <BreadcrumbSec BreadcrumbArray={breadcrumbItems} />
      </div>
      <div className="wow animate__animated animate__fadeInUp">
        <PageTitleImage
          PageTitleImg="/assets/images/all-product-banner.jpg"
          PageTitleMain="All Products"
          PageTitleContent="At Aiiyn, we create furniture that blends timeless Indian tradition with contemporary design. Rooted in craftsmanship and the beauty of teak, our pieces are functional, elegant, and adaptable to your unique lifestyle. We celebrate the quiet moments of life, weaving them into designs that offer comfort, visual harmony, and a lasting sense of home."
        />
      </div>
      <section className="all-product section-space wow animate__animated animate__fadeInUp">
        <div className="container-lg">
          <div className="row gx-5">
            {products.map((productsData) => {
              const firstImage =
                productsData.productImage &&
                productsData.productImage.length > 0
                  ? productsData.productImage[0]
                  : "./path/to/placeholder.jpg";
              return (
                <div className="col-xl-4 col-sm-6" key={productsData.id}>
                  <ProductCard
                    productCardName={productsData.productName}
                    productCardCode={productsData.code}
                    imagePath={firstImage}
                    productDetailsLink={`/products/${productsData.id}`}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </div>
  );
};

export default AllProductsPage;
