import React, { useEffect, useRef } from "react";
import "./aboutSection.scss";
import { AboutCard, MainTitle } from "../command";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay, Pagination } from "swiper/modules";
import CustomButton from "../command/customButton/customButton";
import { useNavigate } from "react-router-dom";

const AboutSection = () => {
  const navigate = useNavigate();

  const handleAboutClick = () => {
    navigate("/aboutus");
  };

  return (
    <section className="home-about-section home-section-space">
      <div className="container-lg">
        <div className="row">
          <div className="col-md-5">
            <div className="about-section-image">
              <img
                alt="about-image"
                src="/assets/images/Our-Intent.jpg"
                className="img-fluid"
              />
            </div>
          </div>
          <div className="col-md-7">
            <div className="about-card-box">
              <div className="about-card-box-center">
                <MainTitle mainTitle="Our Intent" />
                {/* Swiper Slider */}
                <Swiper
                  modules={[Pagination, Autoplay]}
                  className="mySwiper"
                  pagination={{ clickable: true }} // Enables dot navigation
                  autoplay={{
                    delay: 5000, // Set delay between slides (3 seconds)
                    disableOnInteraction: false, // Autoplay won't stop on user interaction
                  }}
                >
                  <SwiperSlide>
                    <AboutCard
                      aboutSubText="‘Our homes are an extension of ourselves.’"
                      aboutText="They play a very imperative role in shaping our lives, they are an annex
                    of our manifestations turned to reality. Every facet of our existence is
                    impacted by design, and it has permeated itself into our way of life. Our
                    designs aspire to go beyond gratifying the aesthetics, an ode to knit the
                    narratives of your life."
                    />
                  </SwiperSlide>
                  <SwiperSlide>
                    <AboutCard
                      aboutSubText="‘Weaving stories in teak’"
                      aboutText="We believe in the power of functionality, ensuring every piece serves a purpose with timeless elegance. We are storytellers, translating your aspirations into reality through the language of form, material, and texture. The rich hues and enduring character of teak become the canvas on which your story unfolds."
                    />
                  </SwiperSlide>
                  <SwiperSlide>
                    <AboutCard
                      aboutSubText="‘Celebrating Indian Homes’"
                      aboutText="We are deeply rooted in the rich heritage of Indian design. We draw inspiration from the past while embracing newness, establishing a design language that is both contemporised and distinctly Indian. Our furniture is a celebration of culture, craftsmanship, and stories passed down through generations."
                    />
                  </SwiperSlide>
                </Swiper>
                <CustomButton
                  btnText="Know More"
                  btnClass="home-button about-button"
                  onClick={handleAboutClick}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutSection;
