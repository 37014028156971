import React from "react";
import "./careerCard.scss";
import { Button } from "react-bootstrap";
import CustomButton from "../customButton/customButton";

const CareerCard = ({
  careerCardClass = "",
  careerCardTitle = "",
  careerCardDetails = "",
  cardPointsArray = [],
  careerEvent,
}) => {
  return (
    <div className={`career-card ${careerCardClass}`}>
      <h3 className="career-card-title">{careerCardTitle}</h3>
      <p className="career-card-details">{careerCardDetails}</p>
      <div className="career-card-points">
        <ul>
          {cardPointsArray.map((cardPointsArrayData) => {
            return <li key={cardPointsArrayData}>{cardPointsArrayData}</li>;
          })}
        </ul>
      </div>
      <CustomButton onClick={careerEvent} btnText="Apply" />
    </div>
  );
};

export default CareerCard;
