import React, { useState } from "react";
import "./appointmentFormContact.scss";
import MainTitle from "../mainTitle/mainTitle";
import { Form } from "react-bootstrap";
import CustomButton from "../customButton/customButton";
import axios from "axios";
import AppointmentFormContactModel from "./appointmentFormContactModel";

const AppointmentFormContact = () => {
  const [modalShow, setModalShow] = React.useState(false);
  const handlesetBook = (e) => {
    setModalShow(true); // Show the modal
  };
  return (
    <>
      <section className="appointment-form-section home-section-space">
        <div className="container-lg">
          <div className="row">
            <div className=" offset-xl-0 col-xl-12">
              <div className="appointment-form-map-box">
                <div className="row">
                  <div className="col-xl-6">
                    <div className="appointment-map">
                      <iframe
                        src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3720.7753236073454!2d72.98842557526031!3d21.16133778052134!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjHCsDA5JzQwLjgiTiA3MsKwNTknMjcuNiJF!5e0!3m2!1sen!2sin!4v1733063918154!5m2!1sen!2sin"
                        allowFullScreen=""
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"
                      ></iframe>
                    </div>
                  </div>
                  <div className="col-xl-6">
                    <div className="appointment-form">
                      <MainTitle mainTitle="Step into Our Creative Space" />
                      <div className="appointment-content">
                        <p>
                          Step into our studio, where design comes to life.
                          We've poured our hearts into each piece, brought to
                          life through collaborations with expert artisans and
                          manufacturers. Here, you'll find a curated collection
                          of our finished furniture, each reflecting our
                          commitment to quality and creativity.
                        </p>
                        <p>
                          We invite you to explore our designs up close feel the
                          materials, and discover the stories behind each
                          creation. Whether you're seeking inspiration or
                          considering a custom piece, we’re excited to share our
                          work with you in a personalized setting.
                        </p>
                      </div>
                      <CustomButton
                        onClick={handlesetBook}
                        btnText="Book an appointment for a studio visit"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <AppointmentFormContactModel
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
};

export default AppointmentFormContact;
