import React from "react";
import "./productCard.scss";
import { Link } from "react-router-dom";

const ProductCard = ({
  imagePath = "",
  imageClass = "",
  cardClass = "",
  productCardName = "",
  productCardCode = "",
  productDetailsLink,
}) => {
  return (
    <Link
      to={productDetailsLink} // Directly use the productDetailsLink here
      className={`${cardClass} product-card`}
    >
      <div className="product-image">
        <img
          src={imagePath}
          className={`${imageClass} img-fluid product-img`}
          alt="product-image"
        />
      </div>
      <div className="product-card-content">
        <h3 className="product-name">{productCardName}</h3>
        <h4 className="product-code-name">{productCardCode}</h4>
      </div>
    </Link>
  );
};

export default ProductCard;
